
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'ShareEmail',
  components: { SaIcon },
  props: {
    url: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    fullUrl(): string {
      return (
        this.url || `${process.env.SAWEB_BASE_URL || ''}${this.$route.fullPath}`
      )
    },
  },
})
