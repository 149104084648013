
import Vue from 'vue'
import CopyText from '~/components/_general/CopyText.vue'

export default Vue.extend({
  name: 'ShareLink',
  components: { CopyText },
  props: {
    url: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    fullUrl(): string {
      return (
        this.url || `${process.env.SAWEB_BASE_URL || ''}${this.$route.fullPath}`
      )
    },
    shareUrl(): string {
      return this.fullUrl.replace('https://', '').replace('http://', '')
    },
  },
})
